import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Error404, Home, Login } from "../views";

export default function RoutePage() {
    return (
        <Router>
            <Routes>
                <Route path="" element={<Home />} />
                <Route path="*" element={<Error404 />} />
                <Route path="ujseg-connexion" element={<Login />} />
            </Routes>
        </Router>
    )
}