import React from "react";
import './Footer.css'

import Facebook from '../../../assets/images/facebook.png'
import Instagram from '../../../assets/images/instagram.png'
import Twitter from '../../../assets/images/Twitter.png'
import Youtube from '../../../assets/images/Youtube.png'

export default function Footer() {
    return (
        <div className="footer">
                <div className="container">     
                    <div className="row">                       
                        <div className="col-lg-4 col-sm-4 col-xs-12 lien-footer">
                            <div className="single_footer">
                                <h4>UJSEG</h4>
                                <ul>
                                    <li><a href="#">Qui sommes-nous ?</a></li>
                                    <li><a href="#">Politique de confidentialité</a></li>
                                </ul>
                            </div>
                        </div> 
                        <div className="col-md-3 col-sm-3 col-xs-12 lien-footer">
                            <div className="single_footer single_footer_address">
                                <h4>LIEN UTILES</h4>
                                <ul>
                                    <li><a href="#">Contactez-nous</a></li>
                                    <li><a href="#">Rendez-vous </a></li>
                                    <li><a href="#">Inscription</a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-5 col-sm-5 col-xs-12 lien-footer">
                            <div className="single_footer single_footer_address">
                                <h4>ABONNEZ-VOUS AUX NEWLETTERS</h4>
                                <div style={{display: "flex", justifyContent: "center"}}>
                                    <div className="form col-md-9">
                                        <form action="">
                                            <div className="form-froup mb-2">
                                                <input type="text" className="form-control" placeholder="nom" />
                                            </div>
                                            <div className="form-froup mb-2">
                                                <input type="text" className="form-control" placeholder="prénom" />
                                            </div>
                                            <div className="form-froup mb-2">
                                                <input type="text" className="form-control" placeholder="email" />
                                            </div>
                                            <div className="form-froup mb-2">
                                                <input type="submit" className="form-control btn btn-danger" value="S'ABONNER" />
                                            </div>
                                        </form>
                                        {/* <div className="suivez-nous">
                                            <h4 className="mt-3">SUIVEZ-NOUS</h4>
                                            <ul>
                                                <li><a href="#">Agenda</a></li>
                                                <li><a href="#">Contactez-nous</a></li>
                                            </ul>
                                        </div> */}
                                    </div>
                                    <div className="social" style={{display: "flex", flexDirection: 'column', marginLeft: 20}}>
                                        <img src={Twitter} alt="Twitter" width={40} />
                                        <img src={Youtube} alt="Youtube" width={40} />
                                        <img src={Facebook} alt="Facebook" width={40} />
                                        <img src={Instagram} alt="Instagram" width={40} />
                                    </div>
                                </div>
                            </div>                          
                        </div>         
                    </div>
                    <div className="row copy">
                        <div className="col-lg-12 col-sm-12 col-xs-12">
                            <p className="copyright">Copyright © 2023 UJSEG. Tous droits réservés.</p>
                        </div>                 
                    </div>                
                </div>
            </div>
    )
}