import { RoutePage } from './routes';

import '@react-pdf-viewer/core/lib/styles/index.css';
/* import '@react-pdf-viewer/default-layout/lib/styles/index.css'; */

function App() {
  return (
    <RoutePage />
  );
}

export default App;
