import React from "react";
import './index.css';
import path288371 from '../../assets/images/path288371.svg';

export default function Contact() {
  return (
    <>
      <div class="map-wrap mt-5">
        <div class="map-content">
          <div className="contact-content row" id="contact">
            <div className="col-md-5">
              <div className="contactez-nous">
                <div className="telephone mb-4">
                  <h4>
                    <i class="fa-solid fa-phone"></i>
                    Téléphone
                  </h4>
                  <p>
                    07 47 01 16 98 | 07 48 33 88 10
                  </p>
                </div>
                <div className="email mb-4">
                  <h4>
                    <i class="fa-solid fa-envelope"></i>
                    E-mail
                  </h4>
                  <p>ujsegabidjan@gmail.com</p>
                </div>
                <div className="adresse mb-4">
                  <h4>
                    <i class="fa-solid fa-location-dot"></i>
                    Adresse
                  </h4>
                  <p>
                    Abidjan Cocody, Boulevard de l'Université, Derrière la RTI
                  </p>
                </div>
                <div className="horaire_ouverture mb-4">
                  <h4>
                    <img src={path288371} alt="" height={23.7833} />
                    Horaires d'ouverture
                  </h4>
                  <div>
                    <p>
                      <b>Lundi - Vendredi</b> : 8H00 - 17H00
                    </p>
                    <p>
                      <b>Samedi</b> : 8H00 - 12H00
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-7">
              <div className="map-card" style={{ marginTop: '3rem' }} >
                <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15889.510665137303!2d-3.9676892!3d5.3592101!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xfc1ed85a0299051%3A0x15fdbf1e4f1d2dd5!2sUJSEG%20ABIDJAN!5e0!3m2!1sen!2sci!4v1699825206801!5m2!1sen!2sci"
                  width="100%"
                  height="400"
                  style={{ border: '0', marginRight: '20 rem' }}
                  allowFullScreen
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                  title="Google Map"></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}