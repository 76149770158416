import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger"
import { ScrollToPlugin } from "gsap/ScrollToPlugin"

gsap.registerPlugin(ScrollTrigger, ScrollToPlugin)

export const sildeInTop = (elem, delay, duration) => {
    gsap.fromTo(
        elem,
        {
            opacity: 0,
            y: -200
        },
        {
            opacity: 1,
            y: 0,
            delay: delay || 0.2,
            duration: duration || 0.3,
            scrollTrigger: {
                trigger: elem,
                start: "top center",
                end: "bottom center",
            },
        }
    )
}

export const sildeInBottom = (elem, delay, duration) => {
    gsap.fromTo(
        elem,
        {
            opacity: 0,
            y: 200
        },
        {
            opacity: 1,
            y: 0,
            delay: delay || 0.2,
            duration: duration || 0.4,
            scrollTrigger: {
                trigger: elem,
                start: "top center",
                end: "bottom center"
            }
        }
    )
}

export const sildeInLeft = (elem, delay, duration) => {
    gsap.fromTo(
        elem,
        {
            opacity: 0,
            x: -200
        },
        {
            opacity: 1,
            x: 0,
            delay: delay || 0.2,
            duration: duration || 0.4,
            scrollTrigger: {
                trigger: elem,
                start: "top center",
                end: "bottom center"
            }
        }
    )
}

export const sildeInRight = (elem, delay, duration) => {
    gsap.fromTo(
        elem,
        {
            opacity: 0,
            x: 200
        },
        {
            opacity: 1,
            x: 0,
            delay: delay || 0.2,
            duration: duration || 0.4,
            scrollTrigger: {
                trigger: elem,
                start: "top center",
                end: "bottom center"
            }
        }
    )
} 

