import React, { useEffect, useState } from "react";
import './Main.css'
import { Formation, Contact } from "../../../../components";

import { gsap } from "gsap";

import image22 from '../../../../assets/images/animateur.jpg'
import partenaire01 from '../../../../assets/images/partenaire01.jpg'
import partenaire02 from '../../../../assets/images/partenaire02.jpg'
import partenaire03 from '../../../../assets/images/partenaire03.jpg'
import partenaire04 from '../../../../assets/images/partenaire04.jpg'

import Slider from "react-slick";
import { Link } from "react-router-dom";

import { Document, Page, pdfjs } from 'react-pdf';

import { downloadPDF } from '../../../../utils';

pdfjs.GlobalWorkerOptions.workerSrc = `https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

export default function Main() {

    const fileName1 = 'Licence en Sciences Economiques et Gestion.pdf';
    const fileName2 = 'LICENCE EN SCIENCES JURIDIQUES site.pdf';
    const fileName3 = 'Master en administration juridique et fiscale des entreprises.pdf';
    const fileName4 = 'Master en Audit et Controle de Gestion.pdf';
    const fileName5 = 'Master en Commerce et Marketing.pdf';
    const fileName6 = 'Master en Droit Privé.pdf';
    const fileName7 = 'Master en Economie de Developpement.pdf';
    const fileName8 = 'Master en Management des Ressources Humaines.pdf';
    const fileName9 = 'Master en Monnaie Finance et Banque.pdf';

    var settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    const [activeTab, setActiveTab] = useState("tab1");

    const handleTabChange = (tab) => {
        setActiveTab(tab);
    };

    useEffect(() => {
        if (activeTab === "tab1") {
            const faculte_navs = document.querySelectorAll('.tab1_faculte_nav')
            faculte_navs.forEach((nav, index) => {
                gsap.fromTo(
                    nav,
                    {
                        opacity: 0,
                        y: 20
                    },
                    {
                        opacity: 1,
                        y: 0,
                        duration: 0.2,
                        delay: index * 0.1,
                        scrollTrigger: {
                            //trigger: nav,
                            start: "top center",
                            end: "bottom center"
                        }
                    }
                )
            })
        } else if (activeTab === "tab2") {
            const faculte_navs = document.querySelectorAll('.tab2_faculte_nav')
            faculte_navs.forEach((nav, index) => {
                gsap.fromTo(
                    nav,
                    {
                        opacity: 0,
                        y: 20
                    },
                    {
                        opacity: 1,
                        y: 0,
                        duration: 0.2,
                        delay: index * 0.1,
                        scrollTrigger: {
                            //trigger: nav,
                            start: "top center",
                            end: "bottom center"
                        }
                    }
                )
            })
        }
    }, [activeTab])

    useEffect(() => {
        const programme_navs = document.querySelectorAll('.programme_nav')
        programme_navs.forEach((nav, index) => {
            gsap.fromTo(
                nav,
                {
                    opacity: 0,
                    y: 20
                },
                {
                    opacity: 1,
                    y: 0,
                    duration: 0.2,
                    delay: index * 0.1,
                    scrollTrigger: {
                        trigger: nav,
                        start: "top center",
                        end: "bottom center"
                    }
                }
            )
        })
    }, [])



    return (
        <div>
            <div id="faculte">
                <h1 className="text-center mb-3 mt-3" style={{ color: "#b30000", textTransform: "uppercase" }}>Decouvrez nos facultés</h1>
                <div className="faculte-content">
                    <ul className="nav nav-tabs" role="tablist">
                        <li className="nav-item" id="science-juridique">
                            <Link
                                className={`nav-link ${activeTab === "tab1" ? "active" : ""}`}
                                onClick={() => handleTabChange("tab1")}
                            >
                                Sciences juridiques
                                <i className={`fa-solid fa-caret-down ${activeTab === "tab1" ? "active-icon" : ""}`}></i>
                            </Link>
                        </li>
                        <li className="nav-item" id="science-economique">
                            <Link className={`nav-link ${activeTab === "tab2" ? "active" : ""}`}
                                onClick={() => handleTabChange("tab2")} >
                                Sciences économiques et gestion
                                <i className={`fa-solid fa-caret-down ${activeTab === "tab2" ? "active-icon" : ""}`}></i>
                            </Link>
                        </li>
                    </ul>
                    <div className="tab-content">
                        <div
                            className={`tab-pane ${activeTab === "tab1" ? "active" : ""}`}
                            role="tabpanel"
                        >
                            <ul>
                                <li className="tab1_faculte_nav">
                                    <div style={{ display: 'none' }}>
                                        <Document file={`${process.env.PUBLIC_URL}/pdf/${fileName2}`}>
                                            <Page pageNumber={1} height={600} />
                                        </Document>
                                    </div>
                                    <Link to="#" onClick={() => downloadPDF(fileName2)}>Licence en Sciences Juridiques</Link>
                                </li>
                                <li className="tab1_faculte_nav">
                                    <div style={{ display: 'none' }}>
                                        <Document file={`${process.env.PUBLIC_URL}/pdf/${fileName3}`}>
                                            <Page pageNumber={1} height={600} />
                                        </Document>
                                    </div>
                                    <Link to="#" onClick={() => downloadPDF(fileName3)}>Master en Administration Juridique et Fiscale des entreprises</Link>
                                </li>
                                <li className="tab1_faculte_nav">
                                    <div style={{ display: 'none' }}>
                                        <Document file={`${process.env.PUBLIC_URL}/pdf/${fileName6}`}>
                                            <Page pageNumber={1} height={600} />
                                        </Document>
                                    </div>
                                    <Link to="#" onClick={() => downloadPDF(fileName6)}>Master en droit privé</Link>
                                </li>
                            </ul>
                        </div>
                        <div
                            className={`tab-pane ${activeTab === "tab2" ? "active" : ""}`}
                            role="tabpanel"
                        >
                            <ul>
                                <li className="tab2_faculte_nav">
                                    <div style={{ display: 'none' }}>
                                        <Document file={`${process.env.PUBLIC_URL}/pdf/${fileName1}`}>
                                            <Page pageNumber={1} height={600} />
                                        </Document>
                                    </div>
                                    <Link to="#" onClick={() => downloadPDF(fileName1)}>Licence en Sciences Economiques et Gestion</Link>
                                </li>
                                <li className="tab2_faculte_nav">
                                    <div style={{ display: 'none' }}>
                                        <Document file={`${process.env.PUBLIC_URL}/pdf/${fileName4}`}>
                                            <Page pageNumber={1} height={600} />
                                        </Document>
                                    </div>
                                    <Link to="#" onClick={() => downloadPDF(fileName4)}>Master en Audit et Controle de Gestion</Link>
                                </li>
                                <li className="tab2_faculte_nav">
                                    <div style={{ display: 'none' }}>
                                        <Document file={`${process.env.PUBLIC_URL}/pdf/${fileName5}`}>
                                            <Page pageNumber={1} height={600} />
                                        </Document>
                                    </div>
                                    <Link to="#" onClick={() => downloadPDF(fileName5)}>Master en Commerce et Marketing</Link>
                                </li>
                                <li className="tab2_faculte_nav">
                                    <div style={{ display: 'none' }}>
                                        <Document file={`${process.env.PUBLIC_URL}/pdf/${fileName7}`}>
                                            <Page pageNumber={1} height={600} />
                                        </Document>
                                    </div>
                                    <Link to="#" onClick={() => downloadPDF(fileName7)}>Master en Economie de Developpement</Link>
                                </li>
                                <li className="tab2_faculte_nav">
                                    <div style={{ display: 'none' }}>
                                        <Document file={`${process.env.PUBLIC_URL}/pdf/${fileName8}`}>
                                            <Page pageNumber={1} height={600} />
                                        </Document>
                                    </div>
                                    <Link to="#" onClick={() => downloadPDF(fileName8)}>Master en Management des Ressources Humaines</Link>
                                </li>
                                <li className="tab2_faculte_nav">
                                    <div style={{ display: 'none' }}>
                                        <Document file={`${process.env.PUBLIC_URL}/pdf/${fileName9}`}>
                                            <Page pageNumber={1} height={600} />
                                        </Document>
                                    </div>
                                    <Link to="#" onClick={() => downloadPDF(fileName9)}>Master en monaine Finance et Banque</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <Formation />
            <div id="animation-programme">
                <h1 className="text-center mb-3 mt-3" style={{ color: "#b30000", textTransform: "uppercase" }}>Les animateurs de programmes</h1>
                <div className="container">
                    <div className="row d-flex flex-wrap">
                        <div className="col-md-5">
                            <img src={image22} alt="" className="image-animateur" />
                        </div>
                        <div className="col-md-2 emoji-main">👉</div>
                        <div className="col-md-5 programme">
                            <ul>
                                Les programmes de Licence et Master sont animés par des :
                                <li className="programme_nav">Enseignants d'Université</li>
                                <li className="programme_nav">Professionnels dans les disciplines enseignées</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid" id="partenaire">
                <h1 className="text-center mb-3 mt-3" style={{ color: "#b30000", textTransform: "uppercase" }}>Nos partenaires</h1>
                <div className="carousel" style={{ margin: "0 1rem" }}>
                    <Slider {...settings}>
                        <div className="wrapper-carousel">
                            <img src={partenaire01} alt="" />
                        </div>
                        <div className="wrapper-carousel">
                            <img src={partenaire02} alt="" />
                        </div>
                        <div className="wrapper-carousel">
                            <img src={partenaire03} alt="" />
                        </div>
                        <div className="wrapper-carousel">
                            <img src={partenaire04} alt="" />
                        </div>
                    </Slider>
                </div>
            </div>
            <Contact />
        </div>
    )
}