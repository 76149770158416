import React from "react";
import './Error404.css'
import { Link } from "react-router-dom";

export default function Error404() {
    return (
        <div id="notfound">
            <div class="notfound">
                <div class="notfound-404">
                    <h1>Oops!</h1>
                </div>
                <h2>404 - Page non trouvé</h2>
                <p>La page que vous recherchez a peut-être été supprimée, son nom a changé ou est temporairement indisponible.</p>
                <Link to="/">Aller à la page d'accueil</Link>
            </div>
        </div>
    )
}