import React, { useEffect, useState } from "react";
import './Formation.css'
import Table from 'react-bootstrap/Table';
import { Link } from "react-router-dom";

import { gsap } from "gsap";

export default function Formation() {
    const [activeTab, setActiveTab] = useState("tab1");

    const handleTabChange = (tab) => {
        setActiveTab(tab);
    };

    useEffect(() => {
        if (activeTab === "tab1") {
            const formation_navs = document.querySelectorAll('.tab1_formation_nav')
            formation_navs.forEach((nav, index) => {
                gsap.fromTo(
                    nav,
                    {
                        opacity: 0,
                        y: 20
                    },
                    {
                        opacity: 1,
                        y: 0,
                        duration: 0.2,
                        delay: index * 0.1,
                        scrollTrigger: {
                            //trigger: nav,
                            start: "top center",
                            end: "bottom center"
                        }
                    }
                )
            })
        } else if (activeTab === "tab2") {
            const formation_navs = document.querySelectorAll('.tab2_formation_nav')
            formation_navs.forEach((nav, index) => {
                gsap.fromTo(
                    nav,
                    {
                        opacity: 0,
                        y: 20
                    },
                    {
                        opacity: 1,
                        y: 0,
                        duration: 0.2,
                        delay: index * 0.1,
                        scrollTrigger: {
                            //trigger: nav,
                            start: "top center",
                            end: "bottom center"
                        }
                    }
                )
            })
        }
    }, [activeTab])

    return (
        <div id="formation">
            <h1 className="text-center mb-4" style={{ color: "#b30000", textTransform: 'uppercase' }}>Formations</h1>
            <div className="formation-content">
                <ul className="nav nav-tabs" role="tablist">
                    <li className="nav-item" id="licence">
                        <Link
                            className={`nav-link ${activeTab === "tab1" ? "active" : ""}`}
                            onClick={() => handleTabChange("tab1")}
                        >
                            Licence
                            <i className={`fa-solid fa-caret-down ${activeTab === "tab1" ? "active-icon" : ""}`}></i>
                        </Link>
                    </li>
                    <li className="nav-item" id="master">
                        <Link className={`nav-link ${activeTab === "tab2" ? "active" : ""}`}
                            onClick={() => handleTabChange("tab2")} >
                            Master
                            <i className={`fa-solid fa-caret-down ${activeTab === "tab2" ? "active-icon" : ""}`}></i>
                        </Link>
                    </li>
                </ul>
                <div className="tab-content">
                    <div
                        className={`tab-pane ${activeTab === "tab1" ? "active" : ""}`}
                        role="tabpanel"
                    >
                        <ul>
                            <li className="tab1_formation_nav">
                                Elle est organisée en 6 semestres de 30 crédits chacun (Normes LMD)
                            </li>
                            <li className="tab1_formation_nav">
                                Les enseignements sont sous formes de Travaux dirigés (TD), Travaux pratiques (TP)
                            </li>
                            <li className="tab1_formation_nav">
                                Durée de formation: 3ans
                            </li>
                        </ul>
                    </div>
                    <div
                        className={`tab-pane ${activeTab === "tab2" ? "active" : ""}`}
                        role="tabpanel"
                    >
                        <ul>
                            <li className="tab2_formation_nav">
                                Elle est organisée en 4 semestres de 30 crédits chacun (Normes LMD)
                            </li>
                            <li className="tab2_formation_nav">
                                Les enseignements sont sous formes de Travaux dirigés (TD), Travaux pratiques (TP)
                            </li>
                            <li className="tab2_formation_nav">
                                Durée de formation: 2ans
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}