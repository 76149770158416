import React, { useState, useEffect } from "react";
import './Header.css'
import { Box, Flex, Image, Text } from "@chakra-ui/react"
//import Slider from "react-slick";
import Carousel from 'react-bootstrap/Carousel';

import logo from '../../../assets/images/logo.png'
import image19 from '../../../assets/images/image19.JPG'
import image22 from '../../../assets/images/image22.png'
import img0932 from "../../../assets/images/IMG-0932.JPG"
import { Link } from "react-router-dom";
import { downloadPDF } from '../../../utils';


const Header = () => {
  const [isNavOpen, setIsNavOpen] = useState(false);

  const toggleNav = () => {
    setIsNavOpen(!isNavOpen);
  };
  const [scroll, setScroll] = useState(0);

  const handleScroll = () => {
    setScroll(window.scrollY);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const headerStyles = {
    //height: scroll > 600 ? "auto" : "500px",
    transform: scroll > 550 ? "translateY(0)" : "translateY(0%)",
    backgroundColor: scroll > 550 ? "#2f3542" : "transparent",
    zIndex: 1000,
    width: "100%",
    transition: "0.3s ease-in-out",
  };

  const settings = {
    //dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
  };

  return (
    <header className="header">
      <nav>
        <div className="logo">
          <img decoding="async" src={logo} alt="Logo" />
        </div>
        <div className={`hamburger ${isNavOpen ? 'toggle' : ''}`} onClick={toggleNav}>
          <div className="bars1"></div>
          <div className="bars2"></div>
          <div className="bars3"></div>
        </div>
        <ul className={`nav-links ${isNavOpen ? 'open' : ''}`}>
          <li>
            <a href="#accueil" onClick={() => downloadPDF("Partie Dossier d'Inscription.pdf")}>Dossier d'inscription</a>
            {/* <span>&nbsp; <i class="fa fa-angle-down"></i></span> */}
            {/*  <ul>
              <li>
                Nos formations &nbsp;
                <ul style={{ marginTop: "3.5rem" }}>
                  <li><a href="#faculte">Facultés</a></li>
                  <li><a href="#organisation_enseignement">Organisation des enseignements</a></li>
                  <li><a href="#evaluation_enseignement">Evaluation des enseignements</a></li>
                  <li><a href="#corps_enseignant">Corps enseignant</a></li>
                </ul>
              </li>
              <li>
                Admission &nbsp;
                <ul>
                  <li><a href="#condition_entree">Conditions d'entrée à ujseg</a></li>
                  <li><a href="#licence">Licence</a></li>
                  <li><a href="#master">Master</a></li>
                  <li><a href="#hebergement">hébergement</a></li>
                  <li><a href="#inscription">Dossier d'inscription</a></li>
                </ul>
              </li>
            </ul> */}
          </li>
          <li>
            <Link to="#" onClick={() => downloadPDF("Info sur internat.pdf")}>Résidence</Link>
          </li>
          <li><Link to="#contact">Contact</Link></li>
        </ul>
      </nav>
      <div className="header-carousel">
        <Carousel data-bs-theme="dark">
          <Carousel.Item interval={2000}>
            <div className="image1" style={{
              backgroundImage: `url(${img0932})`,
            }} />
          </Carousel.Item>
          <Carousel.Item interval={2000}>
            <div className="image2" style={{
              backgroundImage: `url(${image19})`,
            }} />
          </Carousel.Item>
          <Carousel.Item interval={2000}>
            <div className="image3" style={{
              backgroundImage: `url(${image22})`,
            }} />
          </Carousel.Item>
        </Carousel>
      </div>
    </header>
  );
};

export default Header;