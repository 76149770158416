import React from "react";
import './Login.css'

export default function Login() {
    return (
        <>
            <div>
                <h2>Login</h2>
            </div>
        </>
    )
}