export const downloadPDF = (fileName) => {
  const filePath = `${process.env.PUBLIC_URL}/pdf/${fileName}`;

  const link = document.createElement('a');
  link.href = filePath;
  link.download = fileName;

  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
