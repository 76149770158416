import React, { useEffect, useState, useRef } from "react";
import { Footer, Header } from "../../../layouts";
import Main from "./main/Main";
import './Home.css'

import logo from '../../../assets/images/logo.png'
import { Link } from "react-router-dom";

import { gsap } from "gsap";
import { sildeInBottom, sildeInLeft, sildeInRight, sildeInTop } from "../gsap";

export default function Home() {
    const [isLoading, setLoading] = useState(true);

    useEffect(() => {
        setTimeout(() => {
        setLoading(false);
        }, 3000);
    }, []);

    // useEffect(() => {
    //     sildeInBottom('.faculte')
    // }, [])

    // useEffect(() => {
    //     sildeInRight('.faculte h1')
    // }, [])

    // useEffect(() => {
    //     sildeInTop('.faculte .faculte-content')
    // }, [])

    useEffect(() => {
        sildeInBottom('#formation')
    }, [])

    useEffect(() => {
        sildeInTop('#formation h1')
    }, [])

    useEffect(() => {
        sildeInLeft('#formation .formation-content')
    }, [])

    useEffect(() => {
        sildeInBottom('#animation-programme')
    }, [])

    useEffect(() => {
        sildeInLeft('#animation-programme h1')
    }, [])

    useEffect(() => {
        sildeInLeft('#animation-programme h1')
    }, [])

    useEffect(() => {
        sildeInLeft('#animation-programme .col-md-5')
    }, [])

    useEffect(() => {
        sildeInRight('#animation-programme .col-md-7')
    }, [])

    useEffect(() => {
        sildeInBottom('#partenaire')
    }, [])

    useEffect(() => {
        const lienFooters = document.querySelectorAll('#partenaire .carousel .wrapper-carousel')
        lienFooters.forEach((lienFooter, index)  => {
            gsap.fromTo(
                lienFooter,
                {
                    opacity: 0,
                    y: 20
                },
                {
                    opacity: 1,
                    y: 0,
                    duration: 0.5,
                    delay: index * 0.3,
                    scrollTrigger: {
                        trigger: lienFooter,
                        start: "top center",
                        end: "bottom center"
                    }
                }
            )
        })
    }, [])

    useEffect(() => {
        sildeInTop('.map-wrap')
    }, [])

    useEffect(() => {
        sildeInLeft('.contactez-nous')
    }, [])

    useEffect(() => {
        sildeInRight('.map-card')
    }, [])
    
    return (
        <div>
            <div id="loading-wrapper" style={{ display: isLoading ? 'block' : 'none' }}>
                <div id="loading-text" style={{marginTop: "-2rem"}}>
                    <img src={logo} alt="logo" width={100} />
                </div>
                <div id="loading-content"></div>
            </div>
            <div id="content-wrapper" style={{ opacity: isLoading ? 0 : 1 }}>
                <Header />
                <Main />
                <Footer />
            </div>
            <div class="scrolltop-wrap" id="#">
                <Link to="#" role="button" aria-label="Scroll to top">
                    <svg viewBox="0 0 48 48" width="48" height="48" xmlns="http://www.w3.org/2000/svg">
                        <path id="scrolltop-bg" d="M0 0h48v48h-48z"></path>
                        <path id="scrolltop-arrow" d="M14.83 30.83l9.17-9.17 9.17 9.17 2.83-2.83-12-12-12 12z"></path>
                    </svg>
                </Link>
            </div>
        </div>
    )
}